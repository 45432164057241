@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "imports.css";

@config "./tailwind.config.js";

@layer base {
  :root {
    --minor-second: 1.067;
    --major-second: 1.125;
    --minor-third: 1.2;
    --major-third: 1.25;
    --fourth:	1.333;
    --augmented-fourth: 1.414;
    --fifth: 1.5;
    --minor-sixth: 1.6;
    --phi: 1.618;
    --golden: 1.618;
    --major-sixth: 1.667;
    --minor-seventh: 1.778;
    --major-seventh: 1.875;
    --octave: 2;
    --major-tenth: 2.5;
    --major-eleventh: 2.667;
    --major-twelfth: 3;
    --double-octave: 4;

    --ms-ratio: var(--major-third);
    --ms-10: calc(var(--ms-9) * var(--ms-ratio));
    --ms-9: calc(var(--ms-8) * var(--ms-ratio));
    --ms-8: calc(var(--ms-7) * var(--ms-ratio));
    --ms-7: calc(var(--ms-6) * var(--ms-ratio));
    --ms-6: calc(var(--ms-5) * var(--ms-ratio));
    --ms-5: calc(var(--ms-4) / var(--ms-ratio));
    --ms-4: calc(var(--ms-3) / var(--ms-ratio));
    --ms-3: calc(var(--ms-2) / var(--ms-ratio));
    --ms-2: calc(var(--ms-1) / var(--ms-ratio));
    --ms-1: calc(var(--ms0) / var(--ms-ratio));
    --ms0: 1rem; /** Changes this to adjust the base */
    --ms1: calc(var(--ms0) * var(--ms-ratio));
    --ms2: calc(var(--ms1) * var(--ms-ratio));
    --ms3: calc(var(--ms2) * var(--ms-ratio));
    --ms4: calc(var(--ms3) * var(--ms-ratio));
    --ms5: calc(var(--ms4) * var(--ms-ratio));
    --ms6: calc(var(--ms5) * var(--ms-ratio));
    --ms7: calc(var(--ms6) * var(--ms-ratio));
    --ms8: calc(var(--ms7) * var(--ms-ratio));
    --ms9: calc(var(--ms8) * var(--ms-ratio));
    --ms10: calc(var(--ms9) * var(--ms-ratio));
    /** Alternate way, not working in ALL browsers */
    --ms11: pow(var(--ms-ratio), 11);

    --container-gutter: var(--ms1);
    --container-width: 1140px;

    @screen md {
      --container-gutter: var(--ms3);
    }
    @screen lg {
      --container-gutter: var(--ms4);
    }
  }

  h1, .h1 {
    @apply font-secondary text-ms3 leading-none mb-ms0;
    @apply uppercase text-balance;

    @apply md:text-ms4;

    :where(&) strong {
      @apply text-primary-2 font-black text-ms5;

      @apply md:text-ms6;
    }

    .home & {
      @apply md:text-ms5;
      @apply lg:text-ms6;

      & strong {
        @apply md:text-ms7;
      }
    }
  }

  h2, .h2 {
    @apply font-secondary text-ms3 font-bold leading-tight;
    @apply mb-ms0 mt-ms2;
    @apply text-center text-balance;

    @apply flex flex-col items-center;
    @apply after:inline-block after:w-[255px] after:mt-ms0;
    @apply after:border-b-2 after:border-primary-2;

    @apply lg:text-ms4 lg:mb-ms2;

    &.no-underline {
      @apply after:hidden;
    }
  }

  h3, .h3 {
    @apply text-ms2 font-semibold;
    @apply leading-tight mb-ms0 mt-ms2;

    @apply lg:text-ms3;

    :where(&) strong {
      @apply font-semibold text-primary-2;
    }
  }

  h4, .h4 {
    @apply text-ms2 mb-ms0 mt-ms2;
  }

  p {
    @apply my-ms1;

    &.lede, &.lead {
      @apply text-ms1;
    }
  }

  img {
    @apply inline-block;
  }

  a {
    @apply underline underline-offset-4;
    @apply transition duration-200 ease-in-out;

    &:hover {
      @apply text-primary-2;
    }

    &:where([href*="youtube.com"]) {
      @apply block;
      @apply rounded border overflow-hidden;
      @apply relative;
      @apply text-white hover:text-primary-2;

      & img {
        @apply w-full h-full object-cover;
      }

      &:after {
        @apply icon icon-play text-ms5;
        @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
      }
    }

    &:where([target="_blank"]) {
      &:after {
        @apply inline-block ml-ms-4;
        @apply text-ms1 align-text-bottom;
        @apply icon icon-launch;
      }
    }

    &:is([href^="https://maps.app.goo.gl/"]) {
      @apply inline-flex gap-x-ms-4;
      @apply text-white hover:text-primary-2;

      &:has(.icon) {
        @apply no-underline;
      }

      & .icon {
        @apply inline-block;
        @apply text-ms1 text-primary-2;
      }

      &:where([target="_blank"]):after {
        @apply hidden;
      }
    }

    &:is([href^="tel:"]) {
      @apply inline-flex gap-x-ms-4;
      @apply text-white hover:text-primary-2;

      &:has(.icon) {
        @apply no-underline;
      }

      & .icon {
        @apply inline-block;
        @apply text-ms1 text-primary-2;
      }

      &:where([target="_blank"]):after {
        @apply hidden;
      }
    }

    &:is([href^="mailto:"]) {
      @apply inline-flex gap-x-ms-4;
      @apply text-white hover:text-primary-2;

      &:has(.icon) {
        @apply no-underline;
      }

      & .icon {
        @apply inline-block;
        @apply text-ms1 text-primary-2;
      }

      &:where([target="_blank"]):after {
        @apply hidden;
      }
    }
  }

  blockquote {
    quotes: '“' '”' "‘" "’";

    @apply relative ms-ms2 me-ms1 mb-ms1;
    @apply font-bold;

    & p {
      @apply only:m-0;
    }

    &:before, &:after {
      font-size: 3.5em;
      line-height: 0.765;
      @apply absolute;
      @apply font-bold text-primary-2;
      @apply inline-block;
    }

    &:before {
      content: open-quote;
      @apply right-full;
    }

    &:after {
      content: close-quote;
      @apply translate-y-1/4;
    }
  }
}

@layer components {
  @supports(anchor-name: --site-header) {
    header[role="banner"] {
      anchor-name: --site-header;
    }

    footer[role="contentinfo"] {
      anchor-name: --site-footer;
    }
  }

  .container {
    display: grid;
    grid-template-columns:
      [full-start] minmax(var(--container-gutter, var(--ms4)), 1fr)
      [main-start] minmax(1em, var(--container-width, theme("screens.xl"))) [main-end]
      minmax(var(--container-gutter, var(--ms4)), 1fr) [full-end];

    :where(& > *) {
      grid-column: main-start;
    }

    :where(& > .full-page-width) {
      grid-column: full-start / full-end;
    }
  }

  :where(.content) {
    & ul, & ol {
      @apply pl-ms1;

      & li {
        @apply mb-ms-5;

        &:last-child {
          @apply mb-0;
        }
      }
    }

    & ul {
      @apply list-disc;
      @apply marker:text-primary-2;
    }

    & ol {
      @apply list-decimal;
    }

    & a {
      @apply text-primary-2;
    }

    & strong {
      @apply text-primary-2;
    }

    & small, .small {
      @apply block font-normal text-sm;
    }

    & figure, figure& {
      @apply flex flex-col;

      & img {
        @apply w-full;
        @apply border rounded;
      }
    }

    & figure.image {
      @apply relative;

      &:has(.overlay) {
        & img {
          @apply opacity-45;
        }
      }
    }

    & .overlay {
      @apply absolute inset-0;
      @apply p-ms1;
      @apply flex flex-col items-end justify-end;
      @apply text-ms1 leading-tight text-right;
      @apply bg-gradient-to-b from-transparent from-70% to-black;

      :where(&) * {
        @apply leading-none;
      }

      :where(&) strong {
        @apply block;
      }

      :where(figure &) {
        @apply rounded border;
      }
    }
  }

  .content-trim {
    @apply first:[&_*]:mt-0 last:[&_*]:mb-0;
  }

  .button {
    @apply relative inline-block px-ms0 py-ms-2;
    @apply bg-primary-2 text-black;
    @apply rounded-xl no-underline;
    @apply no-underline transition duration-200 ease-in-out;

    @apply hover:text-black hover:bg-[#BEA800];
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .aos-animate {
    opacity: var(--opacity, 1);
    @apply transition-opacity duration-100 ease-in-out;
  }
}

@layer utilities {
  .animate-view {
    animation-timeline: view();
  }
}

dialog.\:popover-open {
  display: block;
}
